import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class MasterService {
  public copyformId: any;
  public showPaste: boolean = false;
  constructor(private httpClient: HttpClient) { }

  submitForm(awardData:
    {
      award: string,
      description: string,
      filedata: any,
      createdByname: string,
      date: Date,
      status: string,
    }) {
    
    return this.httpClient.post(environment.apiUrl + "master/saveAwarddata", awardData);
  }

  Createnews(newsdata: { news: string, description: string, fromdate: Date, todate: Date, image: Text, category: string }) {

    
    return this.httpClient.post(environment.apiUrl + "news/saveNews", newsdata);
  }
  CreateCircular(Circulardata: { circular: string, description: string, fromdate: Date, todate: Date, image: Text, circulardate: string, status: string }) {

   
    return this.httpClient.post(environment.apiUrl + "circular/saveCircularData", Circulardata);
  }

  getAwardData() {
    return this.httpClient.get(environment.apiUrl + "master/getAwardData");
  }

  gettodaysBirthdays() {
   
    return this.httpClient.get(environment.apiUrl + "master/getBirthdayData");
  }
  getAllBirthdayData() {
    
     return this.httpClient.get(environment.apiUrl + "master/getAllBirthdayData");
   }

  
  getNewsData() {
    return this.httpClient.get(environment.apiUrl + "news/getnewsData");
  }

  getAllCircularforMaster() {
    return this.httpClient.get(environment.apiUrl + "circular/getAllCircularforMaster");
  }


  ShowApplication(appId) {
    return this.httpClient.get(environment.apiUrl + "master/getApplication/" + appId);
  }

  ShowAllAwards() {
    return this.httpClient.get(environment.apiUrl + "master/ShowAllAwards");
  }

  getAllImagesWithAward() {
    return this.httpClient.get(environment.apiUrl + "master/getImagesWithAward");
  }
  ShowNews(appId) {
    return this.httpClient.get(environment.apiUrl + "news/getNewsData/" + appId);
  }
  ShowCircular(appId) {
    return this.httpClient.get(environment.apiUrl + "circular/getCircularDataBy/" + appId);
  }
  ShowAllCircular() {
    return this.httpClient.get(environment.apiUrl + "circular/ShowAllCircular");
  }

  getAllImagesWithCircular() {
    return this.httpClient.get(environment.apiUrl + "circular/getAllImagesWithCircular");
  }

  ShowAllNews() {
    return this.httpClient.get(environment.apiUrl + "news/ShowAllNews");
  }

  getAllImagesWithNews() {
    return this.httpClient.get(environment.apiUrl + "news/getAllImagesWithNewsId");
  }

  deleteAward(appId) {
    return this.httpClient.get(environment.apiUrl + "master/deleteAward/" + appId);
  }

  deleteNews(appId) {
    return this.httpClient.get(environment.apiUrl + "news/deleteNews/" + appId);
  }


  deleteCircular(appId) {
    
    return this.httpClient.get(environment.apiUrl + "circular/deleteCircular/" + appId);
  }


  updateForm(awardData: { award: string, description: string, imagedata: any, isActive: string }, appId) {
    
    return this.httpClient.post(environment.apiUrl + "master/updateData/" + appId, awardData);
  }
  updateNewsData(newsData: { news: string, description: string, fromdate: Date, todate: Date, image: any, category: string }, appId) {
  
  return this.httpClient.post(environment.apiUrl + "news/updateData/" + appId, newsData);
  }

  updateCircularData(circularData: { circular: string, description: string, fromdate: Date, todate: Date, image: any, isactive: string, cirdate: string }, appId) {
    
    return this.httpClient.post(environment.apiUrl + "circular/updateData/" + appId, circularData);
  }





}