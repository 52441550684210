import { Component } from '@angular/core';
import { MenuService } from './menu.service';


@Component({
  selector: 'app-menu',
  template: `
    <mat-nav-list appAccordion class="navigation">
      <mat-list-item appAccordionLink >
        <a appAccordionToggle class="relative" routerLink="/dashboard" >
          <i class="fa fa-tachometer fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
          <span>HOME</span>
          <span fxFlex></span>
        
        </a>
      </mat-list-item>

      <mat-list-item appAccordionLink >
        <a appAccordionToggle class="relative" routerLink="/dashboard/connections" >
          <i class="fa fa-database fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
          <span>Connections</span>
          <span fxFlex></span>
        
        </a>
      </mat-list-item>

      <mat-list-item appAccordionLink >
        <a appAccordionToggle class="relative" routerLink="/dashboard/applications" >
          <i class="fa fa-th fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
          <span>Applications</span>
          <span fxFlex></span>
        
        </a>
      </mat-list-item>

      <mat-list-item appAccordionLink >
      <a appAccordionToggle class="relative" routerLink="/dashboard/workflows" >
        <i class="fa fa-users fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
        <span>Workflows</span>
        <span fxFlex></span>
      
      </a>
    </mat-list-item>

    <mat-list-item appAccordionLink >
      <a appAccordionToggle class="relative" routerLink="/dashboard/menu" >
        <i class="fa fa-bars fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
        <span>Menu</span>
        <span fxFlex></span>
      
      </a>
    </mat-list-item>

    <mat-list-item appAccordionLink >
    <a appAccordionToggle class="relative" routerLink="/dashboard/group" >
      <i class="fa fa-users fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
      <span>Group</span>
      <span fxFlex></span>
    
    </a>
  </mat-list-item>

  <mat-list-item appAccordionLink >
    <a appAccordionToggle class="relative" routerLink="/dashboard/accesslevel" >
      <i class="fa fa-shield fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
      <span>Access Level</span>
      <span fxFlex></span>
    
    </a>
  </mat-list-item>

  <mat-list-item appAccordionLink >
  <a appAccordionToggle class="relative" routerLink="/dashboard/user" >
    <i class="fa fa-users fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
    <span>User</span>
    <span fxFlex></span>
  
  </a>
</mat-list-item>

<mat-list-item appAccordionLink >
<a appAccordionToggle class="relative" routerLink="/dashboard/master/awards" >
  <i class="fa fa-trophy fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
  <span>Awards</span>
  <span fxFlex></span>

</a>
</mat-list-item>

<mat-list-item appAccordionLink >
<a appAccordionToggle class="relative" routerLink="/dashboard/master/news" >
  <i class="fa fa-newspaper-o fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
  <span>News</span>
  <span fxFlex></span>

</a>
</mat-list-item>

<mat-list-item appAccordionLink >
<a appAccordionToggle class="relative" routerLink="/dashboard/master/Circular" >
  <i class="fa fa-file fa-2x" style="margin-right:15px;    font-size: 1.4em;"></i>
  <span>Circular</span>
  <span fxFlex></span>

</a>
</mat-list-item>

    </mat-nav-list>`,
  providers: [MenuService]
})
export class MenuComponent {
  currentLang = 'en';

  constructor(
    public menuService: MenuService,
    ) {
  }

  addMenuItem(): void {
    this.menuService.add({
      state: 'menu',
      name: 'MENU',
      type: 'sub',
      icon: 'trending_flat',
      children: [
        { state: 'menu', name: 'MENU' },
        { state: 'timeline', name: 'MENU' }
      ]
    });
  }
}
