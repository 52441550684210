import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

import { Observable } from "rxjs";
import { of } from "rxjs/observable/of";
@Injectable()
export class FormAccessService {
  public itemdata: any = [];
  public edititemdata: any = [];
  public formitemdata: any = [];
  public formedititemdata: any = [];
  public accessId: any;
  public showapp: boolean = false;
  public editshowapp: boolean = false;
  public viewshowapp: boolean = false;
  public menuitemdata: any = [];
  public menuedititemdata: any = [];
  private messageSource = new BehaviorSubject<string>("");
  currentMessage = this.messageSource.asObservable();

  constructor() {}

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  public getItmData(): Observable<any[]> {
    return of(this.itemdata);
  }
}
