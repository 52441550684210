import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { StorageService } from '../webStorage/storage.service';
@Injectable()
export class ApplicationsService {
    
    
    userId: any;
    public ErrorMessage: any = "";
    public Application: any = "";
    public extuserId: any = "";
    constructor(private httpClient: HttpClient,private storageService:StorageService) { }


    getAppMenuList(appId) {
        this.userId = this.storageService.secureStorage.getItem('userId')

        return this.httpClient.get(environment.apiUrl + "formAccess/getAppMenuAccess/" + appId + "/" + this.userId);
    }
  
}