import { Component, OnInit } from '@angular/core';


const SMALL_WIDTH_BREAKPOINT = 960;


@Component({
  selector: 'applications-dashboard',
  templateUrl: './applications-dashboard.component.html',
  styleUrls: ['./applications-dashboard.component.scss'],

})
export class ApplicationsDashboard implements OnInit {
  options = {
    collapsed: false,
    compact: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };



  sideMenu = [{
    state: 'menu',
    name: 'MENU',
    type: 'sub',
    icon: 'trending_flat',
    children: [
      { state: 'menu', name: 'MENU' },
      { state: 'timeline', name: 'MENU' }
    ]
  }];
  config:any;
  menuMouseOver:any;
  menuMouseOut:any;
  updatePS:any;
  mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  url: string;
  sidePanelOpened;

  ngOnInit() {

  }
  constructor() {

  }

  isOver(): boolean {
    return true;
  }

}
