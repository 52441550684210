import { Component, Inject, OnInit } from "@angular/core";
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../services/common/login.service';

import { NgProgress } from "ngx-progressbar";
import { ToastMessageService } from "../../services/common/ToastMessageService.service";
import { StorageService } from 'src/app/services/webStorage/storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: 'change-password.component.html',
  styleUrls:['change-password.component.scss']
})

export class ChangePassword implements OnInit {
  public form: FormGroup;
  errorMsg: String;
  invalid: boolean= false;
  message: string;
  userRole = this.storageService.secureStorage.getItem('empRole');
  showTrue:boolean=false;
  passwordStrength=0;
  notMatchTrue:boolean=false;
  constructor(private fb: FormBuilder, private router: Router, private loginService: LoginService,
   
    @Inject(MAT_DIALOG_DATA) public data: any, public progress: NgProgress,public toastMessageService:ToastMessageService,
    private storageService:StorageService) { }
  onNoClick(): void {
   
    this.loginService.showSpinner = false;
  }

  ngOnInit() {

    this.form = this.fb.group({
      oldpass: [null, Validators.compose([Validators.required])], 
      password: [null, Validators.compose([Validators.required])],
      conpass: [null, Validators.compose([Validators.required])]
    });

  
  }
 
  value: number = 0;
  showProgressBar = false;

  onSubmit() {
    this.progress.start();
    this.loginService.showSpinner = true;

    if(this.passwordStrength == 100){
      this.loginService.updatePassword({
        customerId : this.storageService.secureStorage.getItem('userId'),
        currentPass: this.form.value.oldpass,
        newPass: this.form.value.password,
        confirmPass: this.form.value.conpass,
  
      }).subscribe((data) => {
        if (data["error"]) {
          this.invalid = true;
  
          this.message = data["message"];
        } else {
          this.invalid = true;
          this.message= data["message"];
          this.router.navigate(['/login']);
        
  
        }
      })
    }else{
      
      this.toastMessageService.openSnackBarError("Password must be strong", "")
    }

    


  }

  onStrengthChanged(strength: number) {
    this.passwordStrength=strength;
    
  }

  changeShowTrue(){
    this.showTrue=true;
  }
  checkPassword(){
    if(this.form.controls.password.value==this.form.controls.conpass.value){
      this.notMatchTrue=false;
    }else{
      this.notMatchTrue=true;
    }
  }

  close(){
    
  }

}