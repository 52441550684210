import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './core';
import { SigninComponent } from './core/signin/signin.component';

import { OnlyLoggedInUsersGuard } from './login.guard';
import { ResetPassword } from './core/reset-password/ResetPassword.component';



export const AppRoutes: Routes = [
  
  {
    path: 'login', component: SigninComponent 
  },
  {
    path: 'forgotPassword/:token/:id', component: ResetPassword 
  },
  {
    path: '', component: SigninComponent 
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
	canActivate: [OnlyLoggedInUsersGuard],
    children: [

      {
        path: '',
        loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule)
      },
      {
        path: 'workflows',
        loadChildren: () => import('./form-workflow/workflow.module').then(m => m.WorkflowModule)
      }, {
        path: 'accesslevel',
        loadChildren: () => import('./form-access-level/accesslevel.module').then(m => m.AccessLevelModule)
      }
      ,
      {
        path: 'connections',
        loadChildren: () => import('./dbConnection/connection.module').then(m => m.ConnectionModule)
      },
      {
        path: 'applications',
        loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule)
      },
      {
        path: 'settings',
        children: [

        ]
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'group',
        loadChildren: () => import('./group/group.module').then(m => m.GroupModule)
      }, {
        path: 'menu',
        loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule)
      }, 
      {
        path: 'master',
        loadChildren: () => import('./masters/masters.module').then(m => m.MasterModule)
      },

    ]
  },
  
  {
    path: 'masterLogin',
    loadChildren: () => import('./masters/masters.module').then(m => m.MasterModule)
  },

  {
    path: '**',
    redirectTo: 'session/404'
  }];
