import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/common/login.service';
import { StorageService } from 'src/app/services/webStorage/storage.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  requestTooltip: string = 'Home';
  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleNotificationSidenav = new EventEmitter<void>();

  constructor(public loginService: LoginService, private router: Router,private storageService:StorageService) {

  }

  ngOnInit() {
    if (this.toggleSidenav) {
    }
  }
  getApp() {
    this.router.navigate(['/application-dashboard/application']);
  }
  fullScreenToggle(): void {
  
  }
  logout() {

    var currentUser = this.storageService.secureStorage.getItem('userId');
    var currentUserName = this.storageService.secureStorage.getItem('userName');
    var token = this.storageService.secureStorage.getItem('token');
    this.loginService.setlogouttokentonull(currentUser).subscribe(data => {
      if (data["error"]) {
      } else {
        this.storageService.secureStorage.removeItem('token');
        this.storageService.secureStorage.removeItem('userId');
        this.storageService.secureStorage.removeItem('companyName');
        this.storageService.secureStorage.removeItem('department');
        this.storageService.secureStorage.removeItem('userName');
        this.router.navigate ( [ '/login' ],{ queryParams: { Session: 'LogOut' } } );
      }
    })
    this.storageService.secureStorage.removeItem('token');
    this.storageService.secureStorage.removeItem('userId');
    this.storageService.secureStorage.removeItem('companyName');
    this.storageService.secureStorage.removeItem('department');
    this.storageService.secureStorage.removeItem('userName');
    this.router.navigate ( [ '/login' ],{ queryParams: { Session: 'LogOut' } } );
  }
}
