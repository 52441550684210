import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  icon?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'HOME',
    type: 'link',
    icon: 'fa fa-tachometer fa-2x'
  },
  {
    state: 'connections',
    name: 'Connections',
    type: 'link',
    icon: 'fa fa-database fa-2x',
  },
  {
    state: 'applications',
    name: 'Applications',
    type: 'link',
    icon: 'fa fa-th fa-2x',
  },


  {
    state: 'workflows',
    name: 'Workflows',
    type: 'link',
    icon: 'fa fa-users fa-2x',
  },
  {
    state: 'menu',
    name: 'Menu',
    type: 'link',
    icon: 'fa fa-bars fa-2x',
  },
  {
    state: 'group',
    name: 'Group',
    type: 'link',
    icon: 'fa fa-users fa-2x',
  },
  {
    state: 'accesslevel',
    name: 'Access Level',
    type: 'link',
    icon: 'fa fa-shield fa-2x',
  },
  {
    state: 'user',
    name: 'User',
    type: 'link',
    icon: 'fa fa-user fa-2x',
  }, { state: "master/awards", type: 'link', name: 'Awards', icon: 'fa fa-trophy fa-2x' },

  { state: 'master/news', type: 'link', name: 'News', icon: 'fa fa-newspaper-o fa-2x' },
  { state: 'master/Circular', type: 'link', name: 'Circular', icon: 'fa fa-file fa-2x' },
 
];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
