import { Component, OnInit } from "@angular/core";

import {  ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginService } from '../../services/common/login.service';

import { NgProgress } from "ngx-progressbar";
import { ToastMessageService } from 'src/app/services/common/ToastMessageService.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-ResetPassword',
  templateUrl: 'ResetPassword.component.html',
})

export class ResetPassword implements OnInit {
  public form: FormGroup;
  errorMsg: String;
  invalid: boolean;
  message: string;
  showDetails: true;
  token:any;
  empId:any;
  passwordStrength: number;
  showTrue: boolean=false;
  notMatchTrue: boolean=false;
  showLogin:boolean=false;
  appUrl: string;
  constructor(private fb: FormBuilder, private loginService: LoginService,
    public progress: NgProgress,private route: ActivatedRoute,public ToastMessageService:ToastMessageService) { }
  onNoClick(): void {
    
    this.loginService.showSpinner = false;
  }



  ngOnInit() {
this.appUrl=environment.appUrl;
   this.route.params.subscribe(params => {
      this.token = params['token'];
      this.empId = params['id'];
    })
    this.form = this.fb.group({
      password: [null, Validators.compose([Validators.required])],
       confirmPassword: [null, Validators.compose([Validators.required])]
    });

  
  }
 


  value: number = 0;
  showProgressBar = false;

  onSubmit() {
    this.progress.start();
    this.loginService.showSpinner = true;

if(this.notMatchTrue){
  this.ToastMessageService.openSnackBarError("Confirm password is not matching","")
}else{
  this.loginService.resetPassword({
      
    'password': this.form.value.password,
    'confirmPassword': this.form.value.confirmPassword,
    'token':this.token,
    'empId':this.empId
  }).subscribe(
    (data) => {


      if (data["error"] === false) {
        this.showLogin=true;
        setTimeout(() => {    
          this.ToastMessageService.openSnackBarSuccess(data["message"],"")
        }, 1000);
    
          this.progress.done();
       
      }
      else {
        
        this.progress.done();
        setTimeout(() => {    
          this.ToastMessageService.openSnackBarError(data["message"],"")
        }, 1000);
      }

     
    },
    (error) => {
      this.invalid = true;
      this.progress.done();
      
      this.message = "Please try again!";
    }
  )
  
}
    
  }
  onStrengthChanged(strength: number) {
    this.passwordStrength=strength;
    
  }

  changeShowTrue(){
    this.showTrue=true;
  }
  checkPassword(){
    if(this.form.controls.password.value==this.form.controls.confirmPassword.value){
      this.notMatchTrue=false;
    }else{
      this.notMatchTrue=true;
    }
  }

 
}