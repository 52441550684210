import { Injectable, ViewChild, Directive } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Http, Headers } from '@angular/http';
import { MatPaginator, MatSort } from '@angular/material';
import { StorageService } from '../webStorage/storage.service';


@Injectable()
export class ApplicationServiceService {
  public copyformId: any;
  public showPaste: boolean = false;
  public appserviceid: any;
  public app_id_table;
  public form_id_for_render;
  public form_action_table;
  public tableJsonArray;
  public renderActionTableName: any;
  public renderActionColunName: any;
  public updateRenderFormId: any;
  public updateRenderDataId: any;
  public tableWhereCondition: any;
  @ViewChild(MatPaginator,{ read: true, static: false }) paginator: MatPaginator;
  @ViewChild(MatSort,{ read: true, static: false }) sort: MatSort;
  private jwt = this.storageService.secureStorage.getItem('token');
  private key = { headers: { 'x-access-token': this.jwt } };
  constructor(private httpClient: HttpClient, private Http: Http,private storageService:StorageService) { }

  getApplications() {

    return this.httpClient.get(environment.apiUrl + "application/getApplications");
  }
  getApplicationsForms(app_id) {
    return this.httpClient.get(environment.apiUrl + "form/getApplicationForm/" + app_id);
  }
  submitForm(applicationData: { databaseConnections: String, name: String, icon: String, description: String,user_data:any,user_json:any }) {
    return this.httpClient.post(environment.apiUrl + "application/saveApplication", applicationData);
  }
  deleteApplications(appId) {
    return this.httpClient.get(environment.apiUrl + "application/deleteApplication/" + appId);
  }
  ShowApplication(appId) {
    return this.httpClient.get(environment.apiUrl + "application/getApplication/" + appId);
  }
  UpdateApplication(userData: { databaseConnections: String, name: String, icon: String, description: String,user_data:any,user_json:any }, appId) {
    return this.httpClient.post(environment.apiUrl + "application/updateApplication/" + appId, userData);
  }
  submitFormData(formData: { title: String, name: String, api: String, formType: String, app_id: String, proposedTableName: String, action: String, json_string: String,
    codeComponentTs:String,codeHtmlTs:String,codeScssTs:String, codeService:String,codeRoute:String,
    codeModel:String,appName:String }) {
    return this.httpClient.post(environment.apiUrl + "form/saveForm", formData);
  }

  getForms(app_id) {
    return this.httpClient.get(environment.apiUrl + "form/getForms/" + app_id);
  }


  deleteForm(appId) {
    return this.httpClient.get(environment.apiUrl + "form/deleteForms/" + appId);
  }

  ShowForm(formData: { formId: String }) {
    return this.httpClient.post(environment.apiUrl + "form/getForms", formData);
  }

  

  ShowFormData(formData: { formId: String, appId: String,appName:String }) {
    return this.httpClient.post(environment.apiUrl + "form/getFormsData", formData);
  }


  updateFormData(formData: { title: String, name: String, api: String, formType: String, proposedTableName: String, action: String, json_string: String }, formId) {
    return this.httpClient.post(environment.apiUrl + "form/updateForm/" + formId, formData);
  }
 
  getAPI(form_id) {
    return this.httpClient.get(environment.apiUrl + "form/getAPI/" + form_id);
  }



}
