import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { SigninComponent } from './core/signin/signin.component';
import { AdminLayoutComponent, HeaderComponent, SidebarComponent, MenuComponent } from './core';
import { LoginService } from './services/common/login.service';
import { LoginFormDialog } from './core/signin/LoginFormDialog.component';
import { GroupServiceService } from './services/common/group-service.service';
import { UserServiceService } from './services/common/user-service.service';
import { FormServiceService } from './services/common/form-access-service.service';
import { FormAccessService } from './form-access-level/form-access-service/form-service.service';
import { ApplicationsService } from './services/common/application.service';
import { ApplicationServiceService } from './services/common/application-service.service';
import { MasterService } from './services/common/master.service';
// import { ExcelService } from './services/common/excel.service';
import { DropdownComponentService } from './services/common/dropdown-component-service.service';
import { MatchHeightDirective } from './core/signin/match-height.directive';
import { OnlyLoggedInUsersGuard } from './login.guard';
import { DashboaredComponent } from './application/build-form/componentLib/dashboared-component/dashboared-component.component';
import { ApplicationsDashboard } from './core/applications-dashboard/applications-dashboard.component';
import { ToastMessageService } from './services/common/ToastMessageService.service';
import { ChangePassword } from './applications-dashboard/change-password/change-password.component';
import { ForgotPasswordDialog } from './core/signin/forgotPassword.component';
import { ResetPassword } from './core/reset-password/ResetPassword.component';
import { StorageService } from './services/webStorage/storage.service';
import { LocalService } from './services/webStorage/local.service';
import { CommonDataModule } from './shared-module/commonData.module';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { primengCommonModule } from './shared-module/primengCommon.module';
import { materialCommonModule } from './shared-module/materialCommon.module';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { WebInterceptor } from './auth/web-interceptor';


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    AdminLayoutComponent,
    HeaderComponent,
    SidebarComponent,    
    MenuComponent,
    LoginFormDialog,
    MatchHeightDirective,
    DashboaredComponent,
    ApplicationsDashboard,
    ChangePassword,
    ForgotPasswordDialog,
    ResetPassword

  ],
  imports: [
   
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    CommonDataModule,
    primengCommonModule,
    materialCommonModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  entryComponents: [
    LoginFormDialog,
    ChangePassword,
    ForgotPasswordDialog,
    ResetPassword

  ],
  providers: [ OnlyLoggedInUsersGuard,  DatePipe, LoginService, GroupServiceService, UserServiceService, FormServiceService, FormAccessService, ApplicationsService,
    ApplicationServiceService, MasterService, DropdownComponentService,ToastMessageService,StorageService,LocalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WebInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
