import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";


@Injectable()
export class FormServiceService {
  
    constructor(private httpClient: HttpClient) { }

    saveAccess(accessData: {
        access_level: String,
        access_description: String,
        access_status: String,
        created_by: String,
        updated_by: String,
    }, AppData: { appId: number, appName: String, allow: String }[],
       formData: { formId: number, appId: number, allow: String, create: String, read: String, update: String, delete: String },menuData:any,
    ) 
    {   
        return this.httpClient.post(environment.apiUrl + "formAccess/saveAccess", { "accessData": accessData, "appData": AppData, "formData": formData,"menuData":menuData });
    }


    EditAccess(accessData: {
        access_id:String
        access_level: String,
        access_description: String,
        access_status:String,
        created_by:String,
        updated_by:String,
    },AppData:{appId: number, allow: String}[],
    
     formData:{formId:number, appId:number,allow:String,create:String,read:String,update:String,delete:String},menuData:any,
) {
        return this.httpClient.post(environment.apiUrl + "formAccess/editAccess", {"accessData": accessData, "appData": AppData,"formData":formData,"menuData":menuData });
    }
 
    getAccessList() {
        return this.httpClient.get(environment.apiUrl + "formAccess/getAccess");
    }
    getAccessData(id) {

        return this.httpClient.get(environment.apiUrl + "formAccess/getAccessData/" + id);
    }
    getApplicationForView(access_id) {
        return this.httpClient.get(environment.apiUrl + "formAccess/getApplicationForView/" + access_id);
    }
    getFormsForAccessView(access_id) {
        return this.httpClient.get(environment.apiUrl + "formAccess/getFormForView/" + access_id);
    }

    getMenuForAccessView(access_id) {
        return this.httpClient.get(environment.apiUrl + "formAccess/getMenuForAccessView/" + access_id);
    }

    getSelectedAppForm(Appid) {
        return this.httpClient.post(environment.apiUrl + "form/getSelectedAppForm/", Appid);
    }
    
    deleteAccess(access_id) {
        return this.httpClient.get(environment.apiUrl + "formAccess/deleteAccess/" + access_id);
    }

    getUsers(){
        return this.httpClient.get(environment.apiUrl + "formaccess/getUser");
    }
    saveUserGroup(accessData: {
        access_Id: Number,
        access_Name: String,
        assignment_Control: String,
        assignment_Authorities_Control: String,
        created_by: String,
        updated_by: String,
    }){
        return this.httpClient.post(environment.apiUrl + "formaccess/saveAccessAssignment",accessData);
    }
    getAccessAssignment(accessId){
        return this.httpClient.get(environment.apiUrl + "formaccess/getAccessAssignment/" + accessId);
    }
    deleteAccessAssignment(id){
        return this.httpClient.get(environment.apiUrl + "formaccess/deleteAccessAssignment/" + id);
    }

}







