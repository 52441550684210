import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { StorageService } from '../webStorage/storage.service';
@Injectable()
export class DropdownComponentService {

  userId: any;
  public app_Id:any;
  public tablenames = [];
  public tablenamesDat = [];
  public columNamesLabelDat = [];
  public columNamesValueDat = [];
  public columNamesLabel = [];
  public columNamesValue = [];
  public ValueArray = [];
  public LabelArray = [];
  public Idshow = false;
  public FinalArray = [];
  public ColumnsValueData: String;
  public ColumnsLabelData: String;
  public tableName: String;
  public database_connection:string;
  private jwt = this.storageService.secureStorage.getItem('token');
  private key = { headers: { 'x-access-token': this.jwt } };
  constructor(private httpClient: HttpClient,private storageService:StorageService) { }
  
  getTableName() {
    return this.httpClient.get(environment.apiUrl + "form/getTableName");
  }
  getTableNames(data) {
    
    return this.httpClient.post(environment.apiUrl + "form/getTableNames",data);
  }
  getTableNamesDynamicTable(data) {
    return this.httpClient.get(environment.apiUrl + "form/getTableNamesDynamicTable/"+data);
  }
  foundOrNot() {
    return this.httpClient.get(environment.apiUrl + "form/foundOrNot");
  }
  getDatabaseNames() {
    
    return this.httpClient.get(environment.apiUrl + "form/getDatabaseNames");
  }

  getDatabaseById(databaseValueData) {
        return this.httpClient.get(environment.apiUrl + "form/getDatabaseById/"+databaseValueData);
  }
  getTableColumn(tableName,data) {
    return this.httpClient.post(environment.apiUrl + "form/getTableColumn/" + tableName,data);

  }

  getTableColumnDynamicTable(tableName,data) {
    return this.httpClient.get(environment.apiUrl + "form/getTableColumnDynamicTable/" + tableName+"/"+data);

  }
  getViewNames() {
    return this.httpClient.get(environment.apiUrl + "form/getViewNames");
  }
  getViewName(data) {
    return this.httpClient.post(environment.apiUrl + "form/getViewName",data);
  }


  getTableColumns(tableName) {
    return this.httpClient.get(environment.apiUrl + "form/getTableColumns/" + tableName);
  }
  


  getTableColumnsValueData(tableName, columnName) {
    return this.httpClient.get(environment.apiUrl + "form/getTableColumnsValueData/" + tableName + "/" + columnName);
  }
  

  getActionTableColumns(tableName){
    
  
    return this.httpClient.get(environment.apiUrl + "form/getActionTableColumns/" +  tableName);
  }
  
  getParentTableColumns(tableName){
    
  
    return this.httpClient.get(environment.apiUrl + "form/getParentTableColumns/" +  tableName);
  }

 
}