import { Component, Inject } from '@angular/core';
import { LoginService } from './services/common/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgProgress } from 'ngx-progressbar';
import { MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { MasterService } from './services/common/master.service';
import { ApplicationsService } from './services/common/application.service';

import { ApplicationServiceService } from './services/common/application-service.service';
import { DOCUMENT } from "@angular/common";
import * as CryptoJS from 'crypto-js';
import { LocalService } from './services/webStorage/local.service';
import { StorageService } from './services/webStorage/storage.service';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  userData: any;
  UserId = "";
  APPType = "";
  sub: any = '';
  Session: any = '';
  AccessAppMenuList = [];
  closeOnNavigation: boolean;
  saveMessage: boolean = false;
  expanded: boolean = true;
  showSpinner: boolean;
  errorMsg:String="";
  FormDataList = [];
  listData = [];
  proposedTableName = [];
  


  ngOnInit() {      
   this.loginService.checkToken();
    
    this.sub = this.route.queryParams.subscribe(params => {

      this.UserId = params['UserId'];
      this.APPType = params['application'];
      this.Session = params['Session'];

      var self = this;
      
      if (this.Session == "LogOut") {
        this.storageService.secureStorage.removeItem('token');
        this.storageService.secureStorage.removeItem('userId');
        this.storageService.secureStorage.removeItem('companyName');
        this.storageService.secureStorage.removeItem('department');
        this.storageService.secureStorage.removeItem('userName');
        this.storageService.secureStorage.clear();
      
        this.loginService.checkToken();
        

      } 

    });

  }
  constructor(@Inject(DOCUMENT) private document: Document,private storageService:StorageService,private tokenService:LocalService,private applicationDashboardService: ApplicationsService,private applicationService: ApplicationServiceService, private masterService: MasterService, private fb: FormBuilder, private router: Router, public dialog: MatDialog, public progress: NgProgress, public loginService: LoginService, private route: ActivatedRoute) {


  }

  applicationClick(appId, appName, appConnection) {
    this.errorMsg ="";
   
    this.storageService.secureStorage.setItem('appId', appId);
    this.storageService.secureStorage.setItem('appName', appName);
    

    this.applicationDashboardService.getAppMenuList(appId).subscribe(
      data => {

       
        if (data["error"]) {
         
         this.progress.done();
         
         this.document.body.classList.remove('test');
         this.router.navigate(['/login']);
   

          this.errorMsg = "Application Access Error..Contact support team!!! ";
          this.applicationDashboardService.ErrorMessage= this.errorMsg;


        } else {
          this.AccessAppMenuList = data["Menu"];
          var n;
          var menu_other = "";
          var count = 0;
          JSON.parse(localStorage.getItem("MenuData")).forEach((element) => {
           
            if (count == 0) {
              n = element["label"].search("Dashboard");
             

            }

            if (n != -1 && count == 0) {
              count++;
              menu_other = element["menu_other"];
             

            }

          });
          this.getForms(appId, n, menu_other);

        }
      },
    );
  }
  getForms(appId, n, menu_other) {
   
    this.applicationService.getForms(appId).subscribe(
      data => {
       
        if (data["error"]) {
        } else {

          var bytes = CryptoJS.AES.decrypt(data['RequestData'],  this.storageService.secureStorage.getItem('token')+"KTL@123456");
          var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
       
          this.FormDataList =decryptedData;
          this.FormDataList.forEach(element => {
            this.proposedTableName.push(element["proposed_Table_Name"]);

          });

          let tablename = []
          for (let i = 0; i < this.proposedTableName.length; i++) {
            if (tablename.indexOf(this.proposedTableName[i]) == -1) {
              tablename.push(this.proposedTableName[i])
            }
          }


          if (n != -1) {
            this.router.navigate([menu_other]);
          }
          else {

            this.router.navigate(['/application-dashboard/my']);
          }

          if (this.APPType == "Travel") {

            this.router.navigate(['/application-dashboard/BFLHR-app/BFLDataDashboard']);

          } else {

            this.router.navigate(['application-dashboard/application']);
          }
        }

      },
    );
  }

  ngAfterViewInit() {

    if (this.APPType == "Travel") {

      this.applicationClick('39', 'Travel Route', '1');

    }
  }


}



