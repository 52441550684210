import {
  Directive, ElementRef, AfterViewChecked,
  Input, HostListener
} from '@angular/core';

@Directive({
  selector: '[myMatchHeight]'
})
export class MatchHeightDirective implements AfterViewChecked {
  
  @Input()
  myMatchHeight: string;
  screenHeight: any;
  sliderHeight: any;
  headerHeight: any;
  footerHeight: any;
  constructor(private el: ElementRef) {
  }

  ngAfterViewChecked() {
    
    

  }



  matchHeight(parent: HTMLElement, className: string) {
    

    
    const parentEle = parent.getElementsByClassName(className);

    parent.style.height = 'initial';
    this.screenHeight = window.innerHeight;

    


    this.sliderHeight = this.el.nativeElement.previousElementSibling.clientHeight;

    this.headerHeight = 54

    this.footerHeight = 54

    const itemHeights = this.screenHeight - (this.sliderHeight + this.headerHeight + this.footerHeight);

    
    parent.style.height = `${itemHeights}px`;
    
  }

  @HostListener('window:resize')
  onResize() {

    
    this.matchHeight(this.el.nativeElement, this.myMatchHeight);
  }


}

