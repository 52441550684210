
import { NgModule } from '@angular/core';
import { NgxGalleryModule } from 'ngx-gallery';
import { ChartsModule } from 'ng2-charts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { NgProgressModule } from 'ngx-progressbar';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';

import {DragulaModule } from "ng2-dragula/ng2-dragula";
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from 'ng2-ckeditor';

import { IconPickerModule } from 'ngx-icon-picker';
import { AgmCoreModule } from '@agm/core';
import { ChartModule } from 'primeng/chart';



@NgModule({
  declarations: [
  ],
  imports: [
    
    ChartsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgProgressModule,
    CommonModule,
    NgxGalleryModule,
    MatPasswordStrengthModule,
    CKEditorModule,
    DragulaModule,
    AgmCoreModule,
    IconPickerModule,
    ChartModule
   
  ],
  exports: [
    
    ChartsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    NgProgressModule,
    CommonModule,
    NgxGalleryModule,
    MatPasswordStrengthModule,
    CKEditorModule,
    DragulaModule,
    AgmCoreModule,
    IconPickerModule,
    ChartModule
    


  ],

})
export class CommonDataModule { }
