import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import 'rxjs/add/operator/map';
import { RouteConfigLoadStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { StorageService } from '../webStorage/storage.service';
@Injectable()
export class LoginService {
  userData: any;
  showSpinner: boolean = false;
  constructor(private router: Router, private httpClient: HttpClient,private storageService:StorageService) { }

  doLogin(userData: {
    username: String,
    password: String,
    type: String,
  }) {
    return this.httpClient.post(environment.apiUrl + "login/signin", userData).map(data => {
      if (data["error"]) {
        
        return data;
      } else {

        var bytes = CryptoJS.AES.decrypt(data['Empdata'], "KTL@123456");
        var Empdata = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        

        var bytes1 = CryptoJS.AES.decrypt(data["token"], "KTL@123456");
        var TokenData = JSON.parse(bytes1.toString(CryptoJS.enc.Utf8));


      
       


        if(Empdata["Vintranet"]){
          this.storageService.secureStorage.setItem("token", TokenData);
          this.storageService.secureStorage.setItem("userId", Empdata["USER_NAME"]);
          this.storageService.secureStorage.setItem("userName", Empdata["FULL_NAME"]);
          this.storageService.secureStorage.setItem("department", Empdata["DEPARTMENT"]);
          this.storageService.secureStorage.setItem("empRole", Empdata["emp_role"]);
          this.storageService.secureStorage.setItem("companyName", Empdata["COMPANY_NAME"]);
          this.storageService.secureStorage.setItem("emp_authentication_type",Empdata["emp_authentication_type"])
          

        }else{
          this.storageService.secureStorage.setItem("token", TokenData);
          this.storageService.secureStorage.setItem("userId", Empdata["emp_id"]);
          this.storageService.secureStorage.setItem("userName", Empdata["emp_name"]);
          this.storageService.secureStorage.setItem("empRole", Empdata["emp_role"]);
          this.storageService.secureStorage.setItem("department", Empdata["emp_department"]);
          this.storageService.secureStorage.setItem("companyName", Empdata["emp_company"]);
          this.storageService.secureStorage.setItem("emp_authentication_type",Empdata["emp_authentication_type"])
          

        }
        return data;
      }

    });

  }

  forgotPassword(data:{email:any}){
    return this.httpClient.post(environment.apiUrl + "login/forgotPassword",data);
  }
   setlogouttokentonull(currentUser) {
   
        return this.httpClient.get(environment.apiUrl + "login/setlogouttokentonull/"+currentUser);
    }

  doLoginForVIntranet(userData: {
    username: String,
    password: String,
  }) {
   
    return this.httpClient.post(environment.apiUrl + "login/signinForVIntranet", userData).map(data => {
      if (data["error"]) {
        return data;
      } else {
        
        this.storageService.secureStorage.setItem("token", data["token"]);
        this.storageService.secureStorage.setItem("userId", data["Empdata"]["USER_NAME"]);
        this.storageService.secureStorage.setItem("userName", data["Empdata"]["FULL_NAME"]);
        this.storageService.secureStorage.setItem("department", data["Empdata"]["DEPARTMENT"]);
        this.storageService.secureStorage.setItem("companyName", data["Empdata"]["COMPANY_NAME"]);
        return data;
      }

    });

  }

  isToken(userdata: {
    token: String,
    emp_id: String,
  }) {
    return this.httpClient.post(environment.apiUrl + "login/isToken", userdata);
  }


  checkToken() {
    this.userData = {
      token: this.storageService.secureStorage.getItem("token"),
      emp_id: this.storageService.secureStorage.getItem("userId")
    }

  }

  FromHRPortalLogin(session){
    return this.httpClient.get(environment.apiUrl + "login/FromHRPortalLogin/"+session, );
  }



  updatePassword(data) {
    
    return this.httpClient.post(environment.apiUrl + 'login/updatePassword/', data);
  }
  resetPassword(userdata: {
    password: String,
    confirmPassword: String,
    token:String,
    empId:String
  },
  ){
    return this.httpClient.post(environment.apiUrl + 'login/resetPassword/', userdata);
  }

  ssoCheck(): Observable<any> {
    return this.httpClient.get(environment.apiUrl + "login/checkForSSO", { withCredentials: true });
  }

  getToken() {
    return this.storageService.secureStorage.getItem("token") !== null ? this.storageService.secureStorage.getItem("token") : '';
  }
}