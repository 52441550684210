import {
  MatToolbarModule, MatCardModule, MatExpansionModule, MatTabsModule, MatIconModule, MatGridListModule,
  MatListModule, MatSidenavModule, MatMenuModule, MatTooltipModule, MatButtonModule, MatDialogModule,
  MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, MatSnackBarModule, MatSelectModule,
  MatRadioModule
} from '@angular/material';

import {
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatStepperModule
} from '@angular/material';

import { MatFormFieldModule } from '@angular/material';


import { NgModule } from '@angular/core';



@NgModule({
  declarations: [
  ],
  imports: [
    MatToolbarModule,
    MatCardModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatGridListModule,
    MatListModule,
    MatSidenavModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatFormFieldModule,

  ],
  exports: [

    MatToolbarModule,
    MatCardModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatGridListModule,
    MatListModule,
    MatSidenavModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatSnackBarModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatFormFieldModule

  ],

})
export class materialCommonModule { }
