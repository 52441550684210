import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { StorageService } from '../webStorage/storage.service';
@Injectable()
export class UserServiceService {
    private jwt = this.storageService.secureStorage.getItem('token');
    private key = { headers: { 'x-access-token': this.jwt } };
    constructor(private httpClient: HttpClient,private storageService:StorageService) { }

    saveUser(userData: {
        emp_id:String,
        emp_name: String,
        emp_email: String,
        emp_contact: Number,
        emp_password: String,
        emp_telephone: String,
        emp_company: String,
        emp_authentication_type: String,
        emp_status: String,
        emp_role: String,
        emp_designation: String,
        emp_department: String,
        emp_plant: String,
        emp_reporting_manager: String,
        created_by: String,
        updated_by: String,
        emp_accountexpires: String,
        dob: String,
        
    }) {
        return this.httpClient.post(environment.apiUrl + "user/saveUser", userData);
    }

    getUsers() {
        return this.httpClient.get(environment.apiUrl + "user/getUsers");
    }

    getUserById(user_id) {
        return this.httpClient.get(environment.apiUrl + "user/getUser/" + user_id);
    }

    updateUser(userData: {
        emp_id:String,
        emp_name: String,
        emp_email: String,
        emp_contact: Number,
        emp_telephone:String,
        emp_authentication_type: String,
        emp_status: String,
        emp_role: String,
        emp_company: String,
        emp_designation: String,
        emp_department: String,
        emp_plant: String,
        emp_reporting_manager: String,
        created_by: String,
        updated_by: String,
        emp_accountexpires: String,
        dob:String
    }, user_id) {
		
        return this.httpClient.post(environment.apiUrl + "user/updateUser/" + user_id, userData);
    }

    deleteUser(id) {
        
        return this.httpClient.get(environment.apiUrl + "user/deleteUser/" + id);
    }

    getApproveUsersEmail(emailApproveToString){

        return this.httpClient.get(environment.apiUrl + "user/getApproveUsersEmail/" +emailApproveToString);

    }
    getIntimationUsersEmail(emailIntimationToString){

        return this.httpClient.get(environment.apiUrl + "user/getIntimationUsersEmail/" + emailIntimationToString);

    }

    getApproveUsersEmailGroup(emailGroupString){
        return this.httpClient.get(environment.apiUrl + "user/getApproveUsersEmailGroup/" +  emailGroupString);

    }

}
