import { ToolbarModule } from 'primeng/toolbar';
import { CarouselModule } from 'primeng/carousel';
import { DropdownModule, PanelModule, DialogModule, CalendarModule, ProgressBarModule, InputTextModule, GalleriaModule, InputTextareaModule, ButtonModule, TooltipModule, RadioButtonModule, MultiSelectModule, InputMaskModule } from 'primeng/primeng';
import { NgModule } from '@angular/core';
import { KeyFilterModule } from 'primeng/primeng';
import { FileUploadModule } from 'primeng/fileupload';

import { TabMenuModule } from 'primeng/tabmenu';
import { AccordionModule } from 'primeng/accordion';
import { PasswordModule } from 'primeng/password';
import { SplitButtonModule } from 'primeng/splitbutton'
import { PanelMenuModule } from 'primeng/panelmenu';
import { DragDropModule } from 'primeng/dragdrop';
import { CheckboxModule } from 'primeng/Checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { DataTableModule } from 'primeng/primeng';


@NgModule({
  declarations: [
  ],
  imports: [
    ToolbarModule,
    CarouselModule,
    DropdownModule,
    PanelModule,
    DialogModule,
    CalendarModule,
    ProgressBarModule,
    InputTextModule,
    GalleriaModule,
    InputTextareaModule,
    ButtonModule,
    PanelModule,
    TooltipModule,
    RadioButtonModule,
    KeyFilterModule,
    MultiSelectModule,
    FileUploadModule,
    InputMaskModule,
    TabMenuModule,
    AccordionModule,
    PasswordModule,
    SplitButtonModule,
    PanelMenuModule,
    DragDropModule,
    CheckboxModule,
    AutoCompleteModule,
    DataTableModule
  ],
  exports: [
    ToolbarModule,
    CarouselModule,
    DropdownModule,
    PanelModule,
    DialogModule,
    CalendarModule,
    ProgressBarModule,
    InputTextModule,
    GalleriaModule,
    InputTextareaModule,
    ButtonModule,
    PanelModule,
    TooltipModule,
    RadioButtonModule,
    KeyFilterModule,
    MultiSelectModule,
    FileUploadModule,
    InputMaskModule,
    TabMenuModule,
    AccordionModule,
    PasswordModule,
    SplitButtonModule,
    PanelMenuModule,
    DragDropModule,
    CheckboxModule,
    AutoCompleteModule,
    DataTableModule
  ],

})
export class primengCommonModule { }
