import { Component, Inject, OnInit } from "@angular/core";
import {  MAT_DIALOG_DATA } from '@angular/material';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { LoginService } from '../../services/common/login.service';

import { NgProgress } from "ngx-progressbar";
import { ToastMessageService } from 'src/app/services/common/ToastMessageService.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: 'forgotPassword.component.html',
})

export class ForgotPasswordDialog implements OnInit {
  public form: FormGroup;
  errorMsg: String;
  invalid: boolean;
  message: string;
  showDetails: true;
  constructor(private fb: FormBuilder, private router: Router, private loginService: LoginService,
 
    @Inject(MAT_DIALOG_DATA) public data: any, public progress: NgProgress,public ToastMessageService:ToastMessageService) { }
  onNoClick(): void {
   
    this.loginService.showSpinner = false;
  }



  ngOnInit() {
    this.form = this.fb.group({
      'email': new FormControl('', [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]),
       
    });

  
  }
 


  value: number = 0;
  showProgressBar = false;

  onSubmit() {

    this.progress.start();
    this.loginService.showSpinner = true;


    this.loginService.forgotPassword({
      
      'email': this.form.value.email,
      
    }).subscribe(
      (data) => {


        if (data["error"] === false) {
          
          setTimeout(() => {    
            this.ToastMessageService.openSnackBarSuccess(data["message"],"")
          }, 1000);
            this.invalid = false;
            
         
            this.progress.done();
         
        }
        else {
          this.invalid = true;
         
          this.progress.done();
          this.message=data["message"];
          setTimeout(() => {    
            this.ToastMessageService.openSnackBarError(data["message"],"")
          }, 1000);
        }

       
      },
      (error) => {
        this.invalid = true;
        this.progress.done();
        
        this.message = "Please try again!";
      }
    )

  }
 


}