import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

import { LoginService } from '../../services/common/login.service';

import { NgProgress } from "ngx-progressbar";


@Component({
  selector: 'app-login-form-dialog',
  templateUrl: 'LoginFormDialog.component.html',
})

export class LoginFormDialog implements OnInit {
  public form: FormGroup;
  errorMsg: String;
  invalid: boolean;
  message: string;
  showDetails: true;
  constructor(private fb: FormBuilder, private router: Router, private loginService: LoginService,
  
    @Inject(MAT_DIALOG_DATA) public data: any, public progress: NgProgress,public dialog: MatDialog) { }
  onNoClick(): void {
   
    this.loginService.showSpinner = false;
  }



  ngOnInit() {
    this.form = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
       password: [null, Validators.compose([Validators.required])]
    });

  
  }
 


  value: number = 0;
  showProgressBar = false;

 
  onStrengthChanged(strength: number) {
  }

 
}