import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {MatSnackBar,MatSnackBarConfig} from '@angular/material/snack-bar';
@Injectable()
export class ToastMessageService {
  
    constructor(private _snackBar: MatSnackBar) { }

    openSnackBarSuccess(message: string, action: string) {
      let config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      
      config.duration = 3000;
      config.panelClass ='snackBarSuccess';
      this._snackBar.open(message,action, config);

     
      }


      openSnackBarError(message: string, action: string) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = 'top';
        
        config.duration = 3000;
        config.panelClass ='snackBarError';
        this._snackBar.open(message,action, config);
  
        
        }
}
