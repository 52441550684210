import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { StorageService } from "./services/webStorage/storage.service";

@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {
    constructor(private router: Router, private storageService: StorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.storageService.secureStorage.getItem("userId")) {
            return true;
        }
        this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
