import { LoginService } from './../services/common/login.service';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { StorageService } from "../services/webStorage/storage.service";


@Injectable()
export class WebInterceptor implements HttpInterceptor {
    constructor(private _router: Router, private authService: LoginService,private storageService:StorageService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {
        let updatedRequest = request;
       
        if (this.authService.getToken) {
          updatedRequest = request.clone({
            setHeaders: {
                'x-access-token': this.authService.getToken()
            }
          });
       
        }
        return next.handle(updatedRequest).pipe(
          tap(
            event => {
              //logging the http response to browser's console in case of a success
              if (event instanceof HttpResponse) {
                if (!environment.production) {
                }
              }
            },
            error => {
              //logging the http response to browser's console in case of a failuer
              if (error instanceof HttpErrorResponse) {
                if (!environment.production) console.log("api call error :", error);
                if (error.status === 401) {
                  this._router.navigate([""]);
                }
              }
            }
          )
        );
      }
}