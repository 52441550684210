import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { StorageService } from '../webStorage/storage.service';


@Injectable()
export class GroupServiceService {
    private jwt = this.storageService.secureStorage.getItem('token');
    private key = { headers: { 'x-access-token': this.jwt } };
    constructor(private httpClient: HttpClient,private storageService:StorageService) { }

    saveGroup(groupData: {
        group_name: String,
        group_description: String,
        group_status: String,
        created_by: String,
        updated_by: String,
    }) {
        return this.httpClient.post(environment.apiUrl + "group/saveGroup", groupData);
    }

    getGroups() {
        return this.httpClient.get(environment.apiUrl + "group/getGroups");
    }


    getGroupById(group_id) {
        return this.httpClient.get(environment.apiUrl + "group/getGroup/" + group_id);
    }

    updateGroup(groupData: {
        group_name: String,
        group_description: String,
        group_status: String,
        created_by: String,
        updated_by: String,
    }, group_id) {
        return this.httpClient.post(environment.apiUrl + "group/updateGroup/" + group_id, groupData);
    }

    deleteGroup(group_id) {
        return this.httpClient.get(environment.apiUrl + "group/deleteGroup/" + group_id);
    }

    saveUserGroup(selectedUsers: { id: any }[], groupId) {
        return this.httpClient.post(environment.apiUrl + "group/saveUserGroup/" + groupId, selectedUsers);
    }

    unSelectedUser(group_id) {
        return this.httpClient.get(environment.apiUrl + "group/getUnSelectedUser/" + group_id);
    }

    deleteUser(id, groupId) {
        return this.httpClient.get(environment.apiUrl + "group/deleteUser/" + id + "/" + groupId);
    }




}







